<template>
    <main>
        <div class="container">
            <Datatable endpoint="/plano-faixa">
                <template #modal-title>FAIXA</template>
                <template #modal-body>
                    <div class="row">
                        <div class="col-4">
                            <label for="estado_id">Plano - Estado</label>
                            <b-form-select plain v-model="item.plano_estado_id">
                                <option v-for="planoEstado in plano_estados" :value="planoEstado.id" :key="planoEstado.id">
                                    {{`${planoEstado.plano.descricao} - ${planoEstado.estado?.nome || 'Geral'}`}}
                                </option>
                            </b-form-select>
                        </div>
                        <div class="col-4">
                            <label for="min">Mínimo</label>
                            <b-input type="number" id="min" v-model="item.min" />
                        </div>
                        <div class="col-4">
                            <label for="max">Máximo</label>
                            <b-input type="number" id="max" v-model="item.max" />
                        </div>
                        <div class="col-4">
                            <label for="valor_basico">Valor Básico</label>
                            
                            <money
                                v-model="item.valor_basico"
                                v-bind="{ precision: 2, masked: false }"
                                id="valor_basico"
                                placeholder="0,00"
                                class="form-control"
                                type="text"
                            ></money>
                        </div>
                        <div class="col-4">
                            <label for="valor_diesel">Valor Diesel</label>
                            <money
                                v-model="item.valor_diesel"
                                v-bind="{ precision: 2, masked: false }"
                                id="valor_diesel"
                                placeholder="0,00"
                                class="form-control"
                                type="text"
                            ></money>
                        </div>
                        <div class="col-4"></div>
                    </div>
                </template>
            </Datatable>
        </div>
    </main>
</template>
<script>
    import Datatable from "@/components/Datatable.vue";
    import api from "@/services/api";
    export default {
        name: "Faixas",
        components: { Datatable },
        data() {
            return {
                plano_estados: [],
            };
        },
        computed: {
            item: {
                get() {
                    return this.$store.state.data_table.item;
                },
                set(value) {
                    this.$store.dispatch("setDataTableModalItem", value);
                },
            },
        },
        beforeMount() {
            api.get("plano-estado").then(res => (this.plano_estados = res.data));
        },
    };
</script>
